.emit-table-sort-icons {
    padding-left: 4px;
}

.emit-table-sort-up-arrow {
    padding-bottom: 5px;
}

body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    box-sizing: border-box;
}